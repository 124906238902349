<template>
  <div class="w-full flex justify-center items-start mb-16">
    <div class="w-full mb-2 simpleCard">
      <div v-for="(data, index) in mockup" v-bind:key="index">

        <h2 v-if="data && data.type === 'title' && data.title" class="section text-blue text-left customTitle">
          <b>{{ data.title }}</b>
        </h2>

        <div v-if="data && data.type === 'accordion'" class="section accordionBox">
          <Accordion :header="data.title">

            <div :class="'boxValues'">
              <div v-for="(item, index2) in data.content" v-bind:key="index2"
                :class="processStyles('boxText section', item, 'width', 'section accordionBox')">

                <p v-if="item && item.type === 'text' && item.title">{{ item.title }}</p>
                <small v-if="item && item.type === 'text' && item.subtitle">{{ item.subtitle }}</small>
                <div v-if="item && item.type === 'text' && item.value" class="boxValue">
                  <span>{{ item.value }}</span>
                </div>

                <a-icon v-if="item && item.type === 'file'" type="bell" :style="{ fontSize: '1.6em' }" theme="filled" />
                <p v-if="item && item.type === 'file'">{{ item.title }}</p>
                <button v-if="item && item.type === 'file'">
                  <a-icon type="bell" :style="{ fontSize: '1.6em' }" theme="filled" />
                  <p>Descargar
                    Archivo</p>
                </button>

                <Accordion v-if="item && item.type === 'accordion'" :header="item.title">

                  <div :class="'boxValues'">
                    <div v-for="(item2, index3) in item.content" v-bind:key="index3"
                      :class="processStyles(item2 && item2.type === 'text' ? 'boxText section ' : 'boxFile section ', item2, 'width', 'no class')">

                      <p v-if="item2 && item2.type === 'text' && item2.title">{{ item2.title }}</p>
                      <small v-if="item2 && item2.type === 'text' && item2.subtitle">{{ item2.subtitle }}</small>
                      <div v-if="item2 && item2.type === 'text' && item2.value" class="boxValue">
                        <span>{{ item2.value }}</span>
                      </div>

                      <a-icon v-if="item2 && item2.type === 'file'" type="bell" :style="{ fontSize: '1.6em' }"
                        theme="filled" />
                      <p v-if="item2 && item2.type === 'file'">{{ item2.title }}</p>
                      <button v-if="item2 && item2.type === 'file'">
                        <a-icon type="bell" :style="{ fontSize: '1.6em' }" theme="filled" />
                        <p>Descargar
                          Archivo</p>
                      </button>

                    </div>
                  </div>

                </Accordion>

              </div>
            </div>

          </Accordion>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
export default {
  data() {
    return {
      mockup: [
        {
          type: 'title',
          title: 'Datos de la recomendación por parte de la DCF'
        },
        {
          type: 'accordion',
          title: 'Detalles de la recomendación',
          content: [
            {
              type: 'text',
              title: 'Entidad vigilada',
              value: 'Bancolombia',
              width: 'w-50'
            },
            {
              type: 'text',
              title: 'Tipo de recomendacion',
              value: 'Nueva',
              width: 'w-50'
            },
            {
              type: 'text',
              title: 'Fecha de formulacion de la recomendacion',
              subtitle: 'Año vencido',
              value: '01/01/2022',
              width: 'w-50'
            },
            {
              type: 'text',
              title: 'Fecha de formulacion de la recomendacion',
              subtitle: 'Año actual',
              value: '01/01/2022',
              width: 'w-50'
            },
            {
              type: 'text',
              title: 'Estado de la recomendacion',
              value: 'Seguimiento',
              width: 'w-50'
            },
            {
              type: 'text',
              title: 'Acogimiento de recomendacion',
              value: 'Acogida con la recomendacion de la DCF',
              width: 'w-50'
            },

          ]
        },
        {
          type: 'accordion',
          title: 'Recomendacion a la entidad por parte de la DCF',
          content: [
            {
              type: 'accordion',
              title: '1. Caracterización de la recomendacion',
              content: [
                {
                  type: 'text',
                  title: '1.1 Tema general (Evento o hallazgo)',
                  subtitle: 'Descripcion',
                  value: 'lorem ipsum',
                  width: 'w-100'
                },
                {
                  type: 'text',
                  title: '1.2 Causa raiz',
                  subtitle: 'Describa cual es la causa u origen',
                  value: 'lorem ipsum'
                }
              ]
            },
            {
              type: 'accordion',
              title: '2. Formulacion de la recomendacion del DCF',
              content: [

              ]
            }
          ]
        },
        {
          type: 'title',
          title: 'Consideraciones por parte de la Entidad vigilada'
        },
        {
          type: 'accordion',
          title: 'Consideraciones de la entidad sobre las recomendaciones del DCF',
          content: [

          ]
        },
        ,
        {
          type: 'accordion',
          title: 'Plan(es) de accion propuesto(s) por la entidad',
          content: [

          ]
        },
        ,
        {
          type: 'accordion',
          title: 'Evidencias',
          content: [

          ]
        },

      ]
    }
  },
  components: { Accordion },
  methods: {
    processStyles(base, item, key = 'width', defaultStyle = '') {
      if (item && item[key]) {
        return base + ' ' + item[key]
      } else {
        return defaultStyle
      }
    }
  },
  name: "viewRecommendation",
};
</script>

<style>
.simpleCard {
  margin-right: auto;
  max-width: 1020px;
  border: 1px solid #ddd6d6;
  border-radius: 10px;
  padding: 30px;
}

.accordionBox,
.accordionBox>div {
  width: 100% !important;
}

.boxValues {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
}

.boxValues>div {
  padding: 20px;
}

.customTitle {
  font-size: 1.5em;
}

.section {
  margin-bottom: 10px;
}

.boxText {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: start;
  align-items: start;
  align-content: start;
}

.boxText>p,
.boxText>small {
  color: #939393;
  margin-right: auto;
  font-weight: bold;
}

.boxValue {
  background: #f9f8f8;
  flex-wrap: wrap;
  padding: 10px;
  margin-top: 5px;
  width: 100%;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-content: center;
}

.boxFile {
  background: #f9f8f8;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  border-radius: 15px;
}

.boxFile>button {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  padding: 15px 50px;
  border-radius: 5px;
  color: white;
  background: #004884;
}

.boxFile>button>i {
  margin-right: 10px;
}

.boxFile>i {
  margin-left: 40px;
}

.boxFile>p {
  margin: 0 auto 0 10px;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}
</style>
